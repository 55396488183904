import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import QRCODE from "./images/qrcode.png"
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';


import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

function App() {
  let { id, amount, slug } = useParams()
  const [username, setUsername] = useState("")
  const [currency, setCurrency] = useState(slug)
  const [loading, setLoading] = useState(false)
  const [address, setAddress] = useState("")
  const [transactions, setTransactions] = useState([])


  const copyFn = () => {

    // Get the text field
    var copyText = document.getElementById("address-field");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    Swal.fire("Copied", "Wallet address copied", "success");

  }

  const confirmPayment = () => {
    // const data = {
    //   id,
    //   amount,
    //   currency
    // }
    console.log("working")
    const form = document.getElementById('image-form');
    const imageInput = document.getElementById('file');
    setLoading(true)


    const formData = new FormData();
    formData.append('image', imageInput.files[0]);

    try {
      const response = axios.post(`${API_URL}/confirm-payment.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important!
        },
      })
        .then((res) => {
          Swal.fire('Under Review', 'We are confirming your deposit', 'warning')
            .then(() => {
              handleBack()
            })
          console.log(res.data)
          setLoading(false)
        })


    } catch (error) {
      console.error('Error uploading image:', error);
    }



    // axios.post(`${API_URL}/confirm-payment.php`, {data})
    // .then((res)=>{
    //   Swal.fire('Under Review','We are confirming your deposit','warning')

    // })
  }


  useEffect(() => {
    axios.get(`${API_URL}/get-user.php?id=${id}`)
      .then((res) => {
        const user = res.data
        setUsername(user.username)
        // email = user.email
        // photo = user.image
        //console.log(res.data)
      })

    if (currency == "BTC") {
      setAddress("19fZfkfKijJXJmTnA6DgEhk1zmg7WrY55X")
    }
   else if (currency == "ETH") {
      setAddress("0x45d6dab7f6976fad3003f28ef850ddd465e57cce")
    }
    // else if (currency == "BNB") {
    //   setAddress("0x48196fbfb86fdb35aabfd5c59d56321293db5d5e")
    // }
    else if (currency == "USDT") {
      setAddress("0x45d6dab7f6976fad3003f28ef850ddd465e57cce")
    }
    // else if (currency == "USDT-ERC20") {
    //   setAddress("0xa0CFa327c93f8656688352D8B9cB9Cb94F5963FE")
    // }
    // else if (currency == "USDT-TRC20") {
    //   setAddress("TP5fbjmCbzPWNnaZxR9XDNAsPnBdhXgsjf")
    // }
      else if(currency == "SOL"){
    setAddress("0x45d6dab7f6976fad3003f28ef850ddd465e57cce")
      }

    //  else if(currency == "SHIBA"){
    //   setAddress("0xa0CFa327c93f8656688352D8B9cB9Cb94F5963FE")
    // }
    
    // else if(currency == "TRON"){
    //   setAddress("TP5fbjmCbzPWNnaZxR9XDNAsPnBdhXgsjf")
    // }
    // else if(currency == "LTC"){
    //   setAddress("ltc1qdrsrk9arcr9pdrxmk20mg260pcgnlc33y2uy9g")
    // }
    // else if(currency == "DOGE"){
    //   setAddress("0xa0CFa327c93f8656688352D8B9cB9Cb94F5963FE")
    // }
    // else if(currency == "XRP"){
    //   setAddress("r4JEYwzeMoQ7JDrcqaQ8RpcVt8gvfnX6XL")
    // }
    // else if(currency == "BCH"){
    //   setAddress("qzhlz5yayjvtn5657sg5xhyu2cv0tamarvhmvypge8")
    // }
    // else if(currency == "ADA"){
    //   setAddress("addr1q97t7se98kx7euj9wzrx54flh7qg8xfexx0e7e74e4q9yk5ky49nkczwtfq3f5pffxh0hagxn59rff3xwdgqkc98gf6qfv02j3")
    //  }
    // else if(currency == "xrp"){
    //   setAddress("rKAqeDAFdozwE1iA2V5f79a5VwSKEfuBFB")
    // }
    // else if(currency == "DOGE"){
    //   setAddress("DGVpQFwkes1jMs4TPR59sq96ZdDkZSjb3G")
    // }
    // else if(currency == "ADA"){
    //   setAddress("addr1q864tj9hw2fhtmtky73k4lsayvxm2l5sepyrackx6d7xgkr60tsmph6qhs8v0wal4zqas68r6k7gajhjdqsktyjje50qtw0nqm")
    // }
    // else if(currency == "BCH"){
    //   setAddress("qqxfddtxqmmy64r4mulah6grncnd050ynyea7tkvma")
    // }
    // else if(currency == "Busd"){
    //   setAddress("0x9c4A1cEAC3405B3f0cDBC844a8689F179527d517")
    // }
    // else if(currency == "bnb-bsc"){
    //   setAddress("0x47a51C53D35c2CF43f0D53f13a70017273F8cE4A")
    // }
    // else if(currency == "XRP"){
    //   setAddress("rntVbZZfmFNSPJcAmhbkMb79SnNuNSPwuF")
    // }

    else {
      setAddress("Unavailable")
    }



    axios.get(`${API_URL}/get-transactions.php?id=${id}`)
      .then((res) => {
        //console.log(res.data)
        setTransactions(res.data)
      })

  }, [])

  const handleBack = () => {
    window.history.back()
  }



  return (
    <>
      <div className="welcome-bar">
        <div className="flex-within-welcome">
          <button onClick={handleBack} className="user-icon"><i className='fa fa-arrow-left'></i></button>
          <p>Welcome, {username}</p>



        </div>
      </div>

      <div className="after-container">
        <div className="left-side">
          <div className='header-flex'>

            <h1>INVOICE PAYMENT DETAILS</h1>

            {!loading && (<button className='confirm-pay-btn' id='confirm-btn' onClick={() => { document.getElementById("file").click() }}>confirm payment</button>)
              ||
              <button className='confirm-pay-btn' id='confirm-btn'>Confirming ...</button>
            }
            <form method="post" encType='multipart/form-data' style={{ "display": "inline" }} id='image-form'>
              <input type='file' name="file" id='file' accept="image/*" onChange={confirmPayment} hidden />
            </form>




          </div>

          <p>Pay to:</p>
          <input className="wallet-ad-container px-2" value={`${address}`} id="address-field" readOnly />
          <button className="copy-button" onClick={copyFn}><FontAwesomeIcon icon={faCopy} /> Copy</button>

          <hr className='hr'></hr>
          <p>
            NETWORK:
            {(currency == "BTC") && "Bitcoin"}
            {(currency == "ETH") && "BEP-20"}
            {(currency == "USDC") && "BEP-20"}
            {(currency == "SOL") && "BEP-20"}
          </p>

          <hr className='hr'></hr>


          <p>QR CODE:</p>
          <img src={QRCODE} alt="QR Code" className='qrcode' />
          <p className='p'>If the QR code doesn't work with your wallet, simply copy and paste the <br />  address displayed above.</p>

          <hr className='hr'></hr>

          <p>Invoice Amount:</p>
          <p className='p-bold'>${amount} ON {currency}</p>

          <hr className='hr'></hr>

          <p>Amount Due:</p>
          <p className='p-bold'>${amount} ON {currency}</p>

          <hr className='hr'></hr>




        </div>

        <div className="right-side">

          <h1>PAYMENT(S) HISTORY</h1>

          <p className='p'>Real-time historical records of your incoming invoice payments</p>
          <div className='overflow-cont'>
            <div className='topic-cont'>
              <div className='topics'>TRANSACTION ID</div>

              <div className='topics'>AMOUNT</div>
              <div className='topics'>STATUS</div>
              <div className='topics'>DATE</div>
            </div>


            {
              transactions.length > 0 && (

                transactions.map((trx) => {
                  return (
                    <div key={trx.trx_id} className='transact-cont'>
                      <div className='transact'>#{trx.trx_id}</div>

                      <div className='transact'>${trx.amount}</div>
                      <div className='transact'><div className='status'>{trx.status}</div></div>
                      <div className='transact'>{trx.date} </div>

                    </div>
                  )
                })

              )
            }

            {/* <div className='transact-cont'>
            <div className='transact'>#352</div>
            
            <div className='transact'>$2509.6774193548</div>
            <div className='transact'><div className='status'>PENDING</div></div>
            <div className='transact'>Thursday 10th of August 2023 </div>

          </div>
          <div className='transact-cont'>
            <div className='transact'>#890</div>
           
            <div className='transact'>$2509.6774193548</div>
            <div className='transact'><div className='status'>APPROVED</div></div>
            <div className='transact'>Thursday 10th of August 2023 </div>

          </div> */}

          </div>

        </div>




      </div>



      <div className='modal fade' id='my-modal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              Confirm Payment
            </div>
            <div className='modal-body'>

            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default App;
